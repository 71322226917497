import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSearchService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import {filter, map, takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
import {Router} from '@angular/router';
import {NbAuthService} from '@nebular/auth';
import {Configuration} from '@izabil/inventario';
import {OAuthService} from "angular-oauth2-oidc";
import {AuthService} from "../../../@core/auth/auth.service";

function _window(): any {
  // return the global native browser window object
  return window;
}

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit, OnDestroy {
  get cordova(): any {
    return _window().cordova;
  }

  public promiseScan(): any {
    return new Promise((resolve, reject) => {
      this.cordova.plugins.barcodeScanner.scan(
        (result) => {
          return resolve(result.text);
        },
        (error) => {
          return reject('ERROR');
        },
        {
          formats : "QR_CODE,DATA_MATRIX,CODE_128",
          showTorchButton : true, // iOS and Android
        }
      );
    });
  }

  @HostListener('document:volumedownbutton', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    // do something meaningful with it
    this.promiseScan().then(result => {
      this.router.navigate(['/', 'pages', 'buscar', result]);
    }).catch((ex) => {
      alert(ex);
    });

  }

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Log out' } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private breakpointService: NbMediaBreakpointsService,
              private searchService: NbSearchService,
              private router: Router,
              private authService: NbAuthService,
              public authService2: AuthService,
              private apiConfiguration: Configuration
  ) {
  }

  ngOnInit() {
    this.currentTheme = localStorage.getItem('tema');
    if (localStorage.getItem('tema') === null) {
      this.currentTheme = this.themeService.currentTheme;
      localStorage.setItem('tema', this.currentTheme);
    } else {
      this.changeTheme(localStorage.getItem('tema'));
    }


    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);


    /*
    this.authService.onAuthenticationChange().subscribe((change) => {
      this.authService.getToken().subscribe((token) => {
        if (token.isValid()) {
          this.loginService.userPost().subscribe((user) => {
            this.user = {name: user.nombre + ' ' + user.apellidos };
          });
        }
      });
    });

     */

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.searchService.onSearchSubmit()
      .subscribe((data: any) => {
        this.router.navigate(['/', 'pages', 'buscar', data.term]);
      });


    this.menuService.onItemClick()
      .pipe(
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if (title === 'Log out') {
          /*this.authService.logout('email').subscribe(() => {
            this.apiConfiguration.apiKeys['X-API-KEY'] = '';
            this.router.navigate(['/auth/login']);
          });*/
          if (window.hasOwnProperty('cordova')) {
            this.authService2.logoutNoRedirect()
            this.router.navigate(['/login'])
            window.open(this.authService2.logoutUrl, '_system');

          } else {
            this.authService2.logout()
          }


        }
      });
    this.menuService.onItemClick().subscribe(() => {
      if (window.matchMedia('screen and (max-width: 575px)').matches) {
        this.sidebarService.collapse('menu-sidebar');

      }
      if (window.matchMedia('screen and (min-width: 576px) and (max-width: 991px)').matches) {
        this.sidebarService.compact('menu-sidebar');
      }
      if (window.matchMedia('screen and (min-width: 992px) and (max-width: 1200px)').matches) {
        this.sidebarService.compact('menu-sidebar');
      }
    });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
    localStorage.setItem('tema', themeName);

  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
