import {Component, OnInit} from '@angular/core';
import {environmentversion} from '../../../../environments/environmentversion';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      &copy; IZABIL 2013-{{anioCopy}}. Servicios de Inventario. Versión cliente: {{versionCliente}}.
    </span>
  `,
})

export class FooterComponent implements OnInit {
  anioCopy: any;
  versionCliente = 'NA';
  ngOnInit(): void {
    this.anioCopy = (new Date()).getFullYear().toString();
    this.versionCliente = environmentversion;

  }

}
