import { Component, OnInit } from '@angular/core';
import {OAuthService} from "angular-oauth2-oidc";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../@core/auth/auth.service";

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginOk: any =false;
  loginKo: any =false;


  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {


  }

  lanzarLogin() {
    console.log("LANZAR LOGIN")
    this.authService.login("/pages/dashboard");
  }

}
