<h1 id="title" class="title">Login</h1>
<p class="sub-title">Hej! Log in.</p>


<nb-alert  outline="success" role="alert" *ngIf="loginOk">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li class="alert-message">OK</li>
  </ul>
</nb-alert>


<nb-card>
  <button nbButton status="success" (click)="lanzarLogin()">IZABIL SSO Connect</button>

</nb-card>
