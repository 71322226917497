import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import {Configuration} from "@izabil/inventario";
import {OAuthService} from "angular-oauth2-oidc";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private oauthService: OAuthService,
              private authService: NbAuthService,
              private router: Router,
              private apiConfiguration: Configuration) {
  }

  canActivate() {

    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
          } else {
            if (this.apiConfiguration.apiKeys['X-API-KEY'] === '') {
              this.authService.getToken().subscribe((login) => {
                this.apiConfiguration.apiKeys = {'X-API-KEY': login.getValue()};
              })
            }
          }
        }),
      );


    if (this.oauthService.hasValidIdToken()) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
